<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="text-base font-semibold text-base-600">404</p>
      <h1 class="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">{{ loc.page_not_found }}</h1>
      <p class="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">{{ loc.page_not_found_desc }}</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a href="/" class="rounded-md bg-base-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-base-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-base-600">{{ loc.home_page }}</a>
        <a href="/contact" class="text-sm font-semibold text-gray-900">{{ loc.contact_support }}</a>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "PageNotFound",
}
</script>

<style scoped>

</style>